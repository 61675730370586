import React from 'react';
import Layout from '../../components/layout';
import SEO from '../../components/seo';
import ContentPane from '../../components/contentPane';


const ErrorPage = () => (
  <Layout>
    <SEO title="Paypal Payment Failed" />
    <ContentPane title="Payment Failed" anchor="paypal-error" dark>
      <h1>Something went wrong</h1>
      <p>
        Your spot has not been reserved.
      </p>
      <p>
        If you continue to have problems, please e-mail
        {' '}
        <a href="mailto:contact@criticalhitgames.biz">contact@criticalhitgames.biz</a>
      </p>
    </ContentPane>
  </Layout>
);

export default ErrorPage;
